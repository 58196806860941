import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  fromEvent,
  map,
} from 'rxjs';
import { IService } from '../../models/services-list.model';
import { PublicServicesService } from '../../services/public-services/public-services.service';
import { ToastService } from '../../services/toast/toast.service';
import { Router } from '@angular/router';

@Component({
  selector: 'irembogov-search-box',
  templateUrl: './irembogov-searchbox.component.html',
  styleUrls: ['./irembogov-searchbox.component.scss'],
})
export class IrembogovSearchboxComponent implements AfterViewInit {
  constructor(
    private serviceService: PublicServicesService,
    private toastService: ToastService,
    private router: Router
  ) {}
  @ViewChild('search') searchEl: ElementRef | undefined;

  @Input() value = '';
  @Input() placeholder = 'Search Service name, Application number, Bill ID...';
  @Input() serviceBaseUrl!: string;
  @Input() notFoundNavigateUrl!: string;

  @Output() resultSelected = new EventEmitter<IService>();
  @Output() keyEnterEvent = new EventEmitter<string>();
  @Output() goAdvanceSearch = new EventEmitter<string>();

  focused = false;
  keyword = '';
  searchResults: IService[] = [];
  isSearching = false;

  onKeyUp() {
    this.focused = true;
  }

  setFocus(num: number) {
    this.focused = !!num;
  }

  handleSelect(selectedItem: IService) {
    this.value = selectedItem.serviceName;
    this.resultSelected.emit(selectedItem);

    this.searchResults = [];
  }

  ngAfterViewInit(): void {
    fromEvent(this.searchEl?.nativeElement, 'keyup')
      .pipe(
        map((event: unknown) => {
          this.keyword = '';
          if (event instanceof KeyboardEvent) {
            this.keyword = (event.target as HTMLInputElement).value;
          }
          return this.keyword;
        }),
        filter(res => res.length > 2),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe({
        next: keyword => {
          this.searchByKeyword(keyword);
        },
        error: err => {
          console.log(err);
        },
      });
  }

  onEnter() {
    const inputField = this.searchEl?.nativeElement as HTMLInputElement;
    this.keyEnterEvent.emit(inputField.value);
  }

  goToAdvanceSearch() {
    this.goAdvanceSearch.emit(this.keyword);
  }

  searchByKeyword($event: string) {
    this.isSearching = true;

    this.serviceService
      .getServices(`query=${$event}`, this.serviceBaseUrl)
      .pipe(finalize(() => (this.isSearching = false)))
      .subscribe({
        next: response => {
          this.searchResults = response.data.content;
          if (this.searchResults.length == 0) {
            this.router
              .navigate([this.notFoundNavigateUrl], {
                queryParams: { keyword: $event },
              })
              .catch(err => {
                this.toastService.show({
                  body: err,
                  type: 'error',
                  delay: 5000,
                });
              });
          }
        },
        error: err => {
          this.toastService.show({
            body: err.error.message,
            type: 'error',
            delay: 5000,
          });
        },
      });
  }
}
