import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
  ClientDetailsInterceptor,
  SatHeaderInterceptor,
  initializeKeycloak,
  UiModule,
} from '@irembo-andela/irembogov3-common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { environment } from '../environments/environment';
import { IdleTimerService } from './core/services/idle-timer/idle-timer.service';
import { NgIdleModule } from '@ng-idle/core';
import { WelcomeComponent } from './welcome.component';
import { AddHeaderInterceptor } from './core/interceptors/add-header-interceptor.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import {
  HttpLoaderFactory,
  SharedModule,
} from './modules/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  TranslateLoader,
  TranslateModule,
  TranslateStore,
} from '@ngx-translate/core';
import { LayoutModule } from './layout/layout.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [AppComponent, WelcomeComponent],
  imports: [
    BrowserModule,
    UiModule,
    AppRoutingModule,
    KeycloakAngularModule,
    NgIdleModule.forRoot(),
    HttpClientModule,
    NgIdleKeepaliveModule.forRoot(),
    SharedModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    NgbDropdownModule,
    RouterModule,
    TranslateModule.forRoot({
      defaultLanguage:
        localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
  ],
  providers: [
    TranslateStore,
    {
      provide: APP_INITIALIZER,
      useFactory: (keycloakService: KeycloakService) => {
        return initializeKeycloak(keycloakService, {
          url: environment.authServiceUrl,
          realm: environment.authRealm,
          clientId: environment.authClientId,
          redirectUri: environment.authSuccessRedirectUrl,
        });
      },
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientDetailsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SatHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true,
    },
    IdleTimerService,
    {
      provide: 'environment',
      useValue: environment,
    },
  ],
  bootstrap: [AppComponent],
  exports: [TranslateModule],
})
export class AppModule {}
