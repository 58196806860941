import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IOrganisation } from '../../models/organisations-list.model';

@Component({
  selector: 'irembogov-advance-search-option',
  templateUrl: './irembogov-advance-search-option.component.html',
  styleUrls: ['./irembogov-advance-search-option.component.scss'],
})
export class IrembogovAdvanceSearchOptionComponent {
  @Input() selectedApplicationIcon = '';
  @Input() institutions: IOrganisation[] = [];

  @Output() searchByQuery = new EventEmitter<string>();
  @Output() searchByOrgId = new EventEmitter<string>();

  keyword = '';
}
