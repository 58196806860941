import { Component, Input } from '@angular/core';

@Component({
  selector: 'irembogov-public-service-not-found',
  templateUrl: './irembogov-public-service-not-found.component.html',
  styleUrls: ['./irembogov-public-service-not-found.component.scss'],
})
export class IrembogovPublicServiceNotFoundComponent {
  @Input() keyword = '';
}
