import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { EUserRole } from '../enums/allowed-roles-enums';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  static ALL_PORTAL_USER_ROLES: EUserRole[] = [
    EUserRole.ROLE_AGENCY_ADMIN,
    EUserRole.ROLE_AGENT,
  ];
  constructor(private keycloak: KeycloakService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.isAuthorized(route);
  }

  async isAuthorized(route: ActivatedRouteSnapshot) {
    const routeData: string[] = route.data['roles'];
    if (!(await this.keycloak.isLoggedIn())) {
      return false;
    }

    if (routeData.some(role => this.keycloak.getUserRoles().includes(role))) {
      return true;
    }

    if (
      RoleGuard.ALL_PORTAL_USER_ROLES.some(role =>
        this.keycloak.getUserRoles().includes(role)
      )
    ) {
      this.router.navigate(['dashboard', 'services']);
      return false;
    }

    this.keycloak.logout();
    return false;
  }
}
