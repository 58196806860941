<section>
  <div class="row">
    <div class="col-12 d-flex align-items-center justify-content-center mt-5">
      <div class="search-logo">
        <img src="../../../assets/images/irembo-search.svg" alt="" />
      </div>
    </div>
    <div class="col-12 d-flex align-items-center justify-content-center mt-2">
      <p class="text-center not-found-text">
        We're sorry, but we couldn't find any results for "<strong>{{
          keyword
        }}</strong
        >". <br />
        We're constantly working to improve our search results.
      </p>
    </div>
    <div class="col-12 d-flex align-items-center justify-content-center mt-2">
      <p class="text-center not-found-text">
        In the meantime, Try to browse services by
      </p>
    </div>
  </div>
</section>
