<footer class="footer">
  <div class="w-100">
    <div class="col-12 footer-top">
      <div class="d-flex justify-content-between align-items-center">
        <img src="../../../assets/images/footer-logo.png" alt="Logo" />
        <ul class="list-unstyled list-group list-group-horizontal">
          <li>
            <a href="#" class="text-decoration-none">{{
              'Terms' | translate
            }}</a>
          </li>
          <li>
            <a href="#" class="text-decoration-none">{{
              'Privacy Center' | translate
            }}</a>
          </li>
          <li>
            <a href="#" class="text-decoration-none">{{
              'Cookies' | translate
            }}</a>
          </li>
          <li>
            <a href="#" class="text-decoration-none">{{
              'Support center' | translate
            }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-12 footer-bottom">
      <div
        class="d-flex justify-content-between align-items-center align-content-center">
        <span
          >&copy; {{ currentYear }}
          {{ 'Irembo. All rights reserved' | translate }}</span
        >
        <div class="float-end">
          <a href="#" class="text-decoration-none"
            ><i class="fab fa-twitter"></i
          ></a>
          <a href="#" class="text-decoration-none"
            ><i class="fab fa-facebook-f"></i
          ></a>
          <a href="#" class="text-decoration-none"
            ><i class="fab fa-linkedin-in"></i
          ></a>
          <a href="#" class="text-decoration-none"
            ><i class="fab fa-instagram"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</footer>
