import { Component } from '@angular/core';

@Component({
  selector: 'irembogov-portal-footer',
  templateUrl: './portal-footer.component.html',
  styleUrls: ['./portal-footer.component.scss'],
})
export class PortalFooterComponent {
  currentYear = new Date().getFullYear();
}
