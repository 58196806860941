import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICategory } from '../../models/categories-list.model';
import { IOrganisation } from '../../models/organisations-list.model';

@Component({
  selector: 'irembogov-services-browse-by-panel',
  templateUrl: './irembogov-services-browse-by-panel.component.html',
  styleUrls: ['./irembogov-services-browse-by-panel.component.scss'],
})
export class IrembogovServicesBrowseByPanelComponent {
  @Input() activeFilter?: string;
  @Input() categories: ICategory[] = [];
  @Input() institutions: IOrganisation[] = [];
  @Input() browseby: 'category' | 'institution' = 'category';

  @Output() filterByCategory = new EventEmitter<string>();
  @Output() filterByInstitution = new EventEmitter<string>();
  @Output() browseByChanged = new EventEmitter<'category' | 'institution'>();
}
