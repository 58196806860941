import { Injectable } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class IdleTimerService {
  private state: 'idle' | 'countdown' | 'timedOut' | 'pinging' = 'pinging';
  private _state: BehaviorSubject<string> = new BehaviorSubject<string>(
    this.state
  );
  state$: Observable<string> = this._state.asObservable();
  private lastTimePing!: Date | null;

  private _timeOutDuration = 1800;
  private _timeOutCountDown = 30;

  constructor(
    private idle: Idle,
    private idleKeepalive: Keepalive,
    private authService: AuthService
  ) {}

  start() {
    this.idle.setIdle(this._timeOutDuration);
    this.idle.setTimeout(this._timeOutCountDown);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.state = 'pinging';
      this._state.next(this.state);
      this.resetIdle();
    });

    this.idle.onTimeout.subscribe(() => {
      this.state = 'timedOut';
      this._state.next(this.state);
      this.authService.logOutUser();
    });

    this.idle.onIdleStart.subscribe(() => {
      this.state = 'idle';
      this._state.next(this.state);
    });
    this.idleKeepalive.interval(15);

    this.idleKeepalive.onPing.subscribe(() => (this.lastTimePing = new Date()));

    this.resetIdle();
  }

  timeOutCountDown() {
    return this.idle.onTimeoutWarning.pipe(
      tap(() => {
        this.state = 'countdown';
        this._state.next(this.state);
      })
    );
  }

  resetIdle() {
    this.idle.watch();
    this.state = 'pinging';
    this._state.next(this.state);
  }
}
