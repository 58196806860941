import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {
  IIremboAgent,
  IIremboAgentPermissions,
  IIremboReactivateAgentInfo,
} from '../../models/agents/agent-model';
import { IGetProfileResponse } from '../../models/agentProfile/get-profile-response.model';
import {
  IAgentAccessPermission,
  IAgentNamesAndGender,
  IGetAgentResponse,
} from '../../models/agent/get-agent-response.model';
import { IIremboAgency } from '../../models/agency/get-agency-response.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { IChangePasswordRequest } from '../../models/changePassword/change-password-request';
import { IChangePasswordResponse } from '../../models/changePassword/change-password-response';
import { IGetCitizenResponse } from '../../models/getCitizen/get-citizen-response.model';
import { IFileDownloadResponse } from '../../models/applications/get-application-response.model';
import {
  IHttpPagedResponse,
  IHttpSingleDataResponse,
} from '@irembo-andela/irembogov3-common';
import { EAgentPermissions } from '../../enums/agent-permissions';

@Injectable({
  providedIn: 'root',
})
export class AgentsService {
  private userAgency$: BehaviorSubject<IIremboAgency | undefined> =
    new BehaviorSubject<IIremboAgency | undefined>(undefined);

  private agentPermissions$: BehaviorSubject<EAgentPermissions[]> =
    new BehaviorSubject<EAgentPermissions[]>([]);

  private agentDetailsSource$ = new BehaviorSubject<Record<string, unknown>>(
    {}
  );
  agentDetails$ = this.agentDetailsSource$.asObservable();

  constructor(private http: HttpClient) {}

  // create new agent
  checkAgentExistence(
    nationalId: string
  ): Observable<IHttpSingleDataResponse<IIremboAgent | null>> {
    const headers: HttpHeaders = new HttpHeaders({ nationalId });
    const url = `${environment.apiGatewayBaseUrl}/agent/v1/check-agent-existence`;
    return this.http.get<IHttpSingleDataResponse<IIremboAgent | null>>(url, {
      headers,
    });
  }

  setUserAgency(agency: IIremboAgency) {
    this.userAgency$.next(agency);
  }

  getUserAgency(): IIremboAgency | undefined {
    return this.userAgency$.getValue();
  }

  setPermissions(permissions: EAgentPermissions[]) {
    this.agentPermissions$.next([...permissions]);
  }

  getPermissions(): EAgentPermissions[] {
    return this.agentPermissions$.getValue();
  }

  getUserAgencySubject(): BehaviorSubject<IIremboAgency | undefined> {
    return this.userAgency$;
  }

  // create new agent
  createNewAgent(req: FormData): Observable<any> {
    const headers = {};
    const url = `${environment.apiGatewayBaseUrl}/agent/v1/create`;
    return this.http.post(url, req, { headers });
  }

  // get user info by user id
  getUserById(id: string) {
    const headers: HttpHeaders = new HttpHeaders({
      userId: id,
      userType: 'OTHER',
    });
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/user/user-by-id`;
    return this.http.get<IGetProfileResponse>(url, { headers });
  }

  getAgentByUserId(id: string) {
    const headers = new HttpHeaders();
    const url = `${environment.apiGatewayBaseUrl}/agent/v1/user/${id}`;
    return this.http.get<IGetAgentResponse>(url, { headers });
  }

  getAgentNamesAndGenderbyNid(
    nid: string
  ): Observable<IHttpSingleDataResponse<IAgentNamesAndGender>> {
    const headers = new HttpHeaders({
      'Citizen-Id': nid,
      'endpoint-code': environment.NIDA_GET_ID_INFO_ENDPOINT_CODE,
    });
    const url = `${environment.apiGatewayBaseUrl}/identity/v1/info/name-gender`;
    return this.http.get<IHttpSingleDataResponse<IAgentNamesAndGender>>(url, {
      headers,
    });
  }

  getAgentAccessPermissionEntities(): Observable<
    IHttpPagedResponse<IAgentAccessPermission>
  > {
    const headers = new HttpHeaders();
    const url = `${environment.apiGatewayBaseUrl}/agent/v1/permissions?size=50`;
    return this.http.get<IHttpPagedResponse<IAgentAccessPermission>>(url, {
      headers,
    });
  }

  getAgentAssignedAccessPermissions(): Observable<
    IHttpSingleDataResponse<IIremboAgentPermissions>
  > {
    const headers = new HttpHeaders();
    const url = `${environment.apiGatewayBaseUrl}/agent/v1/assigned-permissions`;
    return this.http.get<IHttpSingleDataResponse<IIremboAgentPermissions>>(
      url,
      {
        headers,
      }
    );
  }

  async updateProfile(id: string, data: FormData) {
    const headers = {};
    const url = `${environment.apiGatewayBaseUrl}/agent/api/v1/updateProfile/${id}`;
    return await fetch(url, { headers, body: data, method: 'PUT' }).then(res =>
      res.json()
    );
  }

  updateAgentDetails(data: FormData): Observable<any> {
    const headers = {};
    const url = `${environment.apiGatewayBaseUrl}/agent/v1/update-my-details`;
    return this.http.post(url, data, { headers });
  }

  // admin suspend agent
  suspendAgent(req: FormData): Observable<any> {
    const url = `${environment.apiGatewayBaseUrl}/agent/v1/suspend-agent`;
    return this.http.patch(url, req, {});
  }

  // admin reactivate agent
  reactivateSingleAgent(id: string, comment: string): Observable<any> {
    const req: IIremboReactivateAgentInfo = {
      ids: [id],
      comment,
    };
    return this.reactivateMultipleAgents(req);
  }

  // admin reactivate multiple agent
  reactivateMultipleAgents(req: IIremboReactivateAgentInfo): Observable<any> {
    const url = `${environment.apiGatewayBaseUrl}/agent/v1/reactivate-agents`;
    return this.http.patch(url, req, {});
  }

  // get agents by agency id filtered
  getAgentsByAgencyId(agencyId: string, filterParams: string) {
    const headers = new HttpHeaders({});
    const url = `${environment.apiGatewayBaseUrl}/agent/v1/get-agents-by-agency-id/${agencyId}?${filterParams}`;
    return this.http.get<IHttpPagedResponse<IIremboAgent>>(url, { headers });
  }

  // get agent by agent id
  getAgentById(id: string): Observable<IHttpSingleDataResponse<IIremboAgent>> {
    const headers = new HttpHeaders({ agentId: id });
    const url = `${environment.apiGatewayBaseUrl}/agent/v1/agent-by-id`;
    return this.http.get<IHttpSingleDataResponse<IIremboAgent>>(url, {
      headers,
    });
  }

  // auth update user password
  changePassword(
    updatePasswordRequest: IChangePasswordRequest
  ): Observable<any> {
    const headers = new HttpHeaders({});
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/update-password`;
    return this.http.put<IChangePasswordResponse>(url, updatePasswordRequest, {
      headers,
    });
  }

  // identity get citizen information
  getCitizen(citizenId: string) {
    return this.http.get<IGetCitizenResponse>(
      `${environment.apiGatewayBaseUrl}/identity/v1/info/name`,
      {
        headers: { 'Citizen-Id': citizenId },
      }
    );
  }

  //update user profile picture
  updateUserProfile(req: FormData): Observable<any> {
    const headers = new HttpHeaders({});
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/update-profile-picture`;
    return this.http.put<any>(url, req, {
      headers,
    });
  }

  // get user profile picture
  getProfilePicture(id: string, userType: string) {
    const headers = new HttpHeaders({ userId: id, userType: userType });
    const url = `${environment.apiGatewayBaseUrl}/auth/v1/user/profile-picture`;
    return this.http.get<IFileDownloadResponse>(url, { headers });
  }

  reactivateAgents(ids: string[]): Observable<any> {
    const url = `${environment.apiGatewayBaseUrl}/agent/v1/reactivate-agents`;
    return this.http.patch(url, { ids: ids, comment: 'reactivate-agents' });
  }

  getSuspensionInfo(id: string): Observable<any> {
    const url = `${environment.apiGatewayBaseUrl}/agent/v1/agent-suspension-by-id`;
    return this.http.get(url, { headers: { agentId: id } });
  }

  updateAgentProfile(
    agentId: string,
    params: Record<string, unknown>
  ): Observable<any> {
    const headers = new HttpHeaders({});
    const url = `${environment.apiGatewayBaseUrl}/agent/v1/update-agent-profile/${agentId}`;
    return this.http.put<any>(url, params, {
      headers,
    });
  }

  removeAgent(formData: FormData): Observable<any> {
    const headers = new HttpHeaders();
    const options = {
      headers: headers,
      body: formData,
    };
    const url = `${environment.apiGatewayBaseUrl}/agent/v1/remove-agent`;
    return this.http.delete<any>(url, options);
  }

  getFeaturedServices(): Observable<any> {
    const url = `${environment.apiGatewayBaseUrl}/agent/v1/featured-services`;
    const headers = new HttpHeaders({});
    return this.http.get(url, { headers });
  }

  updateFeaturedServices(
    serviceIds: Record<string, string[]>
  ): Observable<any> {
    const headers = new HttpHeaders({});
    const url = `${environment.apiGatewayBaseUrl}/agent/v1/agent/featured-services`;
    return this.http.put<any>(url, serviceIds, { headers });
  }

  getAgencyInfoById(
    agencyId: string
  ): Observable<IHttpSingleDataResponse<IIremboAgency>> {
    const headers = new HttpHeaders();
    const url = `${environment.apiGatewayBaseUrl}/admin/v1/agencies/${agencyId}`;
    return this.http.get<IHttpSingleDataResponse<IIremboAgency>>(url, {
      headers,
    });
  }

  setAgentDetails(details: Record<string, unknown>) {
    this.agentDetailsSource$.next(details);
  }

  getAgentFile(fileName: string) {
    const url = `${environment.apiGatewayBaseUrl}/agent/v1/file-manager`;
    return this.http.get(url, { params: { fileName }, responseType: 'blob' });
  }
}
