<div class="dashboard-wrapper min-vh-100 position-relative">
  <div class="i-navbar pb-4 position-fixed">
    <div class="nav-item d-flex justify-content-between my-3 text-white px-5">
      <div class="nav-item-section-left">
        <div
          class="d-flex flex-row align-items-center align-content-center menu-block-no-wrap">
          <div
            class="px-2 pb-1 d-flex justify-content-center align-content-center align-items-center">
            <img
              src="../assets/images/footer-logo.png"
              alt=""
              height="20"
              width="109" />
          </div>
          <div class="d-flex flex-row align-items-center align-content-center">
            <div
              class="px-2 d-flex flex-row align-items-center align-content-center">
              <span class="role-name px-2">{{
                currentRole?.code === EUserRole.ROLE_AGENCY_ADMIN
                  ? 'AGENT PORTAL'
                  : 'AGENT'
              }}</span>
              <span class="px-2">|</span>
              <span class="px-2 text-uppercase" *ngIf="agencyName">{{
                agencyName
              }}</span>
            </div>
            <a
              routerLink="{{ item.routerLink }}"
              *ngFor="let item of navbarItems"
              class="px-2 text-decoration-none"
              [class.active]="item.routerLink === currentRoute">
              {{ item.name | translate }}</a
            >
          </div>
        </div>
      </div>
      <div class="nav-item-section-right">
        <div
          class="d-flex flex-row justify-content-end align-items-center align-content-center">
          <a
            href="{{ item.action }}"
            *ngFor="let item of navbarActions"
            class="px-2 text-decoration-none">
            {{ item.name | translate }}</a
          >
          <div ngbDropdown class="px-2">
            <div class="me-4" ngbDropdownToggle role="button">
              <span class="me-2 locale-emoji rounded-circle">{{
                getLocaleIcon(activeLocale)
              }}</span>
              <span class="active-locale">{{ activeLocaleName }}</span>
            </div>
            <ul
              class="dropdown-menu dropdown-menu-end"
              ngbDropdownMenu
              aria-labelledby="userProfile_dd">
              <li class="" *ngFor="let item of supportedLocales">
                <a
                  ngbDropdownItem
                  class="dropdown-item cursor-pointer"
                  (click)="changeLocale(item)">
                  <div class="d-flex flex-row justify-content-between">
                    <div class="me-4 locale-name">
                      <span class="me-2 locale-emoji">{{
                        getLocaleIcon(item.locale)
                      }}</span>
                      {{ item.name }}
                    </div>

                    <div>
                      <i
                        class="fa-regular"
                        [ngClass]="{
                          'fa-circle-check locale-checked-color':
                            activeLocale === item.locale,
                          'fa-circle locale-unchecked-color':
                            activeLocale !== item.locale
                        }"></i>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <irembogov-navbar-profile-section
            profile
            [username]="userProfile?.firstName + ' ' + userProfile?.lastName"
            [roles]="undefined"
            [activeRole]="currentRole?.name"
            (logout)="openLogoutModal()"
            (roleChange)="roleChange($event)"
            [navItems]="profileSectionNavActionItems"
            (navItemClick)="handleNavAction($event)"
            (profileClick)="handleProfileClick()"
            profileLinkText="{{ 'View Profile' | translate }}"
            [updatedProfile]="
              profilePicture
                ? 'data:' +
                  profilePicture.contentType +
                  ';base64,' +
                  profilePicture.file
                : ''
            "
            [agentNationalId]="agentNationalId"
            [showUserDetails]="false">
          </irembogov-navbar-profile-section>
        </div>
      </div>
    </div>
  </div>
  <div class="content min-vh-100 main-app-content-wrapper">
    <router-outlet></router-outlet>
  </div>
  <irembogov-portal-footer></irembogov-portal-footer>
</div>
<ng-template #logoutModalTemplate let-modal tabIndex="-1" class="modal-dialog">
  <div
    class="modal-content p-2"
    style="font-family: 'Plus Jakarta Sans', sans-serif">
    <div class="modal-header py-3 border-bottom-0">
      <img
        src="./../assets/images/feature-icon.png"
        alt="warning-icon"
        width="48px"
        height="48px" />
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        (click)="modal.close()"
        aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <h5
        class="modal-title"
        id="logoutModalLabel"
        style="
          font-size: 18px;
          color: #101828;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
        ">
        {{ 'Confirm log out' | translate }}
      </h5>
      <p
        style="
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          color: #475467;
        ">
        {{
          'Are you sure you want to logout? All ongoing actions will be lost'
            | translate
        }}.
      </p>
    </div>
    <div class="row px-2 pb-3">
      <div class="col-6">
        <button
          type="button"
          class="btn btn btn-outline-secondary w-100"
          (click)="modal.dismiss()"
          style="color: #344054">
          {{ 'Cancel' | translate }}
        </button>
      </div>

      <div class="col-6">
        <button
          type="button"
          class="btn text-white w-100"
          (click)="modal.close('logout')"
          style="background-color: #0063cf">
          {{ 'Yes, log out' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #logoutWarningContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'Pending incomplete process!' | translate }}
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p>
      {{ 'Are you sure you want to cancel this process' | translate }}?
      {{
        'If yes, you might lose all the information you have provided, consider saving your draft before leaving'
          | translate
      }}.
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('cancel')">
      {{ 'Cancel' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-danger"
      (click)="modal.close('logout')">
      {{ 'Logout' | translate }}
    </button>
  </div>
</ng-template>

<irembogov-offcanvas
  [title]="currentPaneContentTypeData.title"
  [paneOpen]="sidePaneOpen"
  [description]="currentPaneContentTypeData.description"
  (OnPaneToggle)="paneToggle($event)"
  [roundedWrapContent]="currentPaneContentTypeData.roundedWrapContent">
  <ng-template offCanvasContent>
    <div
      *ngIf="
        (paneContentType | async) === paneContentTypeEnum.UPDATE_PROFILE_PICTURE
      ">
      <form
        class="form"
        [formGroup]="updateProfileInfo"
        enctype="multipart/form-data">
        <div class="row">
          <label class="text-lead my-2" for="profilePicture">{{
            'Profile Picture(optional)' | translate
          }}</label>
          <div class="col-md-9 mx-auto">
            <div class="row">
              <div class="col-md-12 mx-auto">
                <img
                  [src]="imgFile"
                  alt=""
                  class="img-fluid w-100 rounded selected-image-section border"
                  *ngIf="imgFile !== undefined && imgFile !== ''" />
                <img
                  src="../assets/image-user.png"
                  alt=""
                  class="img-fluid w-100 bg-light rounded selected-image-section"
                  *ngIf="
                    !profilePicture && (imgFile === undefined || imgFile === '')
                  " />
                <img
                  [src]="
                    'data:' +
                    (hasProfilePic ? profilePicture.contentType : '') +
                    ';base64,' +
                    (hasProfilePic ? profilePicture.file : '')
                  "
                  alt=""
                  class="img-fluid w-100 rounded selected-image-section border"
                  *ngIf="
                    profilePicture && (imgFile === undefined || imgFile === '')
                  " />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="row buttons-row">
                  <div class="col-md-3 ml-auto">
                    <button
                      class="btn irembo-badge danger my-2 btn-sm remove-image-button"
                      (click)="resetInputFile()"
                      *ngIf="imgFile !== undefined && imgFile !== ''">
                      <i class="fa-solid fa-trash-can"></i>
                    </button>
                  </div>
                  <div class="col-md-3 mx-auto">
                    <button class="btn irembo-badge primary my-2 btn-sm ms-4">
                      <div class="btn-upload">
                        <input
                          type="file"
                          id="profilePicture"
                          name="profilePicture"
                          (change)="prFileChange($event)"
                          #inputFile />
                        <i class="fa-solid fa-upload"></i>
                        {{ 'choose image' | translate }}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row my-2">
          <div class="col-md-3 mx-auto pe-5 ps-5">
            <button type="button" class="btn btn-light" (click)="onClosePane()">
              {{ 'Cancel' | translate }}
            </button>
          </div>
          <div class="col-md-4 mr-auto pe-3 ps-3">
            <button
              type="button"
              class="btn btn-primary"
              [disabled]="
                imgFile === '' || imgFile === undefined ? true : false
              "
              (click)="updateUserProfile()">
              <div
                class="spinner-border spinner-border-sm text-light"
                role="status"
                *ngIf="isUpdateLoading">
                <span class="visually-hidden">Loading...</span>
              </div>
              {{ !isUpdateLoading ? 'Update' : 'Loading' }}
            </button>
          </div>
        </div>
      </form>
    </div>
    <div
      *ngIf="(paneContentType | async) === paneContentTypeEnum.CHANGE_PASSWORD">
      <form [formGroup]="changePasswordForm">
        <label for="currentPassword" class="form-label">{{
          'Current Password' | translate
        }}</label>
        <div class="input-group mb-2">
          <input
            type="password"
            class="form-control"
            id="currentPassword"
            placeholder="{{ 'Enter current password' | translate }}"
            formControlName="currentPassword" />
          <div
            class="form-error w-100 small text-danger"
            *ngIf="
              changePasswordForm.controls['currentPassword'].touched &&
              !changePasswordForm.controls['currentPassword'].valid
            ">
            {{ 'Current password is required' | translate }}
          </div>
        </div>
        <label for="newPassword" class="form-label">{{
          'New Password' | translate
        }}</label>
        <div class="input-group mb-2">
          <input
            type="password"
            class="form-control"
            id="newPassword"
            placeholder="{{ 'Enter new password' | translate }}"
            formControlName="newPassword" />
          <div
            class="form-error w-100 small text-danger"
            *ngIf="
              changePasswordForm.controls['newPassword'].touched &&
              !changePasswordForm.controls['newPassword'].valid
            ">
            {{
              'Password should be at least 8 characters long, and may contain letters and numbers'
                | translate
            }}
          </div>
        </div>
        <label for="confirmPassword" class="form-label">{{
          'Confirm Password' | translate
        }}</label>
        <div class="input-group mb-2">
          <input
            type="password"
            class="form-control"
            id="confirmPassword"
            placeholder="{{ 'Confirm Password' | translate }}"
            formControlName="confirmPassword" />
          <div
            class="form-error w-100 small text-danger"
            *ngIf="
              changePasswordForm.controls['confirmPassword'].touched &&
              !changePasswordForm.controls['confirmPassword'].valid
            ">
            {{
              'Password should be at least 8 characters long, and may contain letters and numbers'
                | translate
            }}
          </div>
        </div>
        <div class="text-end pt-4">
          <button
            type="button"
            class="btn btn-transparent text-muted mx-3"
            (click)="cancelForm()">
            {{ 'Cancel' | translate }}
          </button>
          <button
            class="btn btn-primary mt-1 mx-auto"
            type="button"
            [disabled]="isChangePasswordLoading"
            (click)="onFormSubmit()">
            <div
              *ngIf="isChangePasswordLoading"
              class="spinner-border spinner-border-sm mr-2"
              role="status"></div>
            {{ 'Update' | translate }}
          </button>
        </div>
      </form>
    </div>
  </ng-template>
</irembogov-offcanvas>
