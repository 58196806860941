import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private pendingProcessForLogout = false;
  private _showLogOutWarningModal: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public showLogOutWarningModal$: Observable<boolean> =
    this._showLogOutWarningModal.asObservable();

  constructor(
    private http: HttpClient,
    private keyCloakService: KeycloakService,
    private offCanvasService: NgbOffcanvas
  ) {}

  updateIncompleteProcessState(state: boolean): void {
    this.pendingProcessForLogout = state;
  }

  logOutUser(): void {
    const hasOpenOffCanvas: boolean = this.offCanvasService.hasOpenOffcanvas();
    if (this.pendingProcessForLogout || hasOpenOffCanvas) {
      this._showLogOutWarningModal.next(true);
      return;
    }
    this._showLogOutWarningModal.next(false);
    this.keyCloakService.logout();
  }
}
