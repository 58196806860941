import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IService } from '../../models/services-list.model';

@Component({
  selector: 'irembogov-browse-by-service-card',
  templateUrl: './irembogov-browse-by-service-card.component.html',
  styleUrls: ['./irembogov-browse-by-service-card.component.scss'],
})
export class IrembogovBrowseByServiceCardComponent {
  @Input() service?: IService;
  @Input() knowledgeBaseUrl?: string;
  @Output() navigateToApply = new EventEmitter<IService>();

  showMore = false;
  showMoreText = 'More Details';

  openDetails(serviceId: string | undefined) {
    this.showMore = !this.showMore;
    if (serviceId && this.showMore) {
      this.showMoreText = 'Less Details';
    } else {
      this.showMoreText = 'More Details';
    }
  }
}
