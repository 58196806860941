export interface IGetAgencyResponse {
  agencyName: string;
  agencyEmail: string;
  agencyAddress: string;
  tinNumber: string;
  description: string;
  id: string;
  status: StatusTypeEnum;
}

export interface IIremboAgency {
  id: string;
  name: string;
  email: string;
  tinNumber?: string;
  businessCertificate?: string;
  iremboGovContract?: string;
  approvedById: string;
  approvedDate: string;
  createdById: string;
  createdDate: string;
  status: StatusTypeEnum;
  users: Array<Record<string, unknown>>[];
  type?: EAgencyType;
}

export enum EAgencyType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export enum StatusTypeEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
