<div class="section">
  <div class="col-12 text-center mx-auto alt-search">
    <a
      class="btn btn-outline-primary bg-irembo-blue find-button text-light me-3 mr-15"
      (click)="buttonClick.emit()">
      <i aria-hidden="true" class="{{ icon }} me-2"></i>
      {{ label }}
      <i aria-hidden="true" class="fa fa-arrow-right ms-2"></i>
    </a>
  </div>
</div>
