<div class="main-search mw mx-auto">
  <div class="input-group search-box-container">
    <input
      type="input"
      class="form-control form-control-lg search-box"
      placeholder="{{ placeholder }}"
      #search
      (keyup)="onKeyUp()"
      (focus)="setFocus(1)"
      (blur)="setFocus(0)"
      [value]="value"
      (keydown.enter)="onEnter()" />
    <span (click)="onEnter()" class="input-group-text search-box px-4">
      <i aria-hidden="true" class="fa fa-magnifying-glass"></i>
    </span>
    <span class="searching" *ngIf="isSearching">
      <div
        class="spinner-border text-bg-irembo-blue spinner-border-sm"
        role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </span>
    <span *ngIf="search.value" class="clear-search" (click)="search.value = ''">
      <i aria-hidden="true" class="fa fa-times"></i> Clear
    </span>
    <ul
      class="predictions position-absolute py-2 px-0"
      *ngIf="search.value && focused">
      <li
        class="px-4"
        *ngFor="let result of searchResults"
        (mousedown)="handleSelect(result)">
        <span> {{ result.serviceName }} </span>
        <small class="badge text-bg-secondary ms-2">{{
          result.organization.name
        }}</small>
      </li>
      <li class="px-3" (mousedown)="goToAdvanceSearch()">
        <button class="btn btn-link apply-link">
          More results <i class="fa fa-arrow-right"></i>
        </button>
      </li>
    </ul>
  </div>
</div>
