import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IHttpPagedResponse } from '@irembo-andela/irembogov3-common';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ILocale } from '../../models/locales.model';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  private baseUrl = environment.apiGatewayBaseUrl + '/admin/v1';

  supportedLocales: BehaviorSubject<ILocale[]> = new BehaviorSubject<ILocale[]>(
    []
  );

  constructor(private http: HttpClient) {}

  getSupportedLocales(): Observable<IHttpPagedResponse<ILocale>> {
    return this.http.get<IHttpPagedResponse<ILocale>>(
      `${this.baseUrl}/public/languages?page=0&size=50`
    );
  }
}
