import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@irembo-andela/irembogov3-common';
import { RoleGuard } from './core/guards/role.guard';
import { NgModule } from '@angular/core';
import { WelcomeComponent } from './welcome.component';
import { EUserRole } from './core/enums/allowed-roles-enums';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    component: WelcomeComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: [EUserRole.ROLE_AGENCY_ADMIN, EUserRole.ROLE_AGENT] },
    children: [
      {
        path: 'services',

        loadChildren: () =>
          import('./modules/services/services.module').then(
            m => m.ServicesModule
          ),
        canActivate: [RoleGuard],
        data: { roles: [EUserRole.ROLE_AGENCY_ADMIN, EUserRole.ROLE_AGENT] },
      },
      {
        path: 'agents',

        loadChildren: () =>
          import('./modules/agents/agents.module').then(m => m.AgentsModule),
        canActivate: [RoleGuard],
        data: { roles: [EUserRole.ROLE_AGENCY_ADMIN] },
      },
      {
        path: 'stats',
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(
            m => m.DashboardModule
          ),
        canActivate: [RoleGuard],
        data: { roles: [EUserRole.ROLE_AGENCY_ADMIN, EUserRole.ROLE_AGENT] },
      },
      {
        path: 'applications',
        loadChildren: () =>
          import('./modules/applications/applications.module').then(
            m => m.ApplicationsModule
          ),
        canActivate: [RoleGuard],
        data: { roles: [EUserRole.ROLE_AGENCY_ADMIN, EUserRole.ROLE_AGENT] },
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./modules/user/user.module').then(m => m.UsersModule),
        canActivate: [RoleGuard],
        data: { roles: [EUserRole.ROLE_AGENCY_ADMIN, EUserRole.ROLE_AGENT] },
      },
      {
        path: 'support',
        loadChildren: () =>
          import('./modules/support/support.module').then(m => m.SupportModule),
        canActivate: [RoleGuard],
        data: { roles: [EUserRole.ROLE_AGENT] },
      },
    ],
  },
  {
    path: 'application-management',
    loadChildren: () =>
      import(
        './modules/application-management/application-management.module'
      ).then(m => m.ApplicationManagementModule),
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [EUserRole.ROLE_AGENT],
    },
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];
@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
