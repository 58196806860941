import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IHttpPagedResponse } from '../../../utils/models/http-paged-response.model';
import { IService } from '../../models/services-list.model';
import { IFeatureService } from '../../models/featured-service.model';

@Injectable({
  providedIn: 'root',
})
export class PublicServicesService {
  constructor(private http: HttpClient) {}

  getServices(queryParams: string, servicesBaseUrl: string) {
    return this.http.get<IHttpPagedResponse<IService>>(
      `${servicesBaseUrl}/service/api/v1/public/services?${queryParams}`
    );
  }

  getFeaturedServices(servicesBaseUrl: string) {
    return this.http.get<IHttpPagedResponse<IFeatureService>>(
      `${servicesBaseUrl}/service/api/v1/public/featured-services`
    );
  }
}
