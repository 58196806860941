export const environment = {
  customemailValidationRegex:
    /^[^<>()[\]\\,;:%#^\s"$&!@]+@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/,
  customnationalIdValidationRegex: /^[1-3](19|20)\d{2}[7-8]\d{7}[0-9]\d{2}$/,
  noWhiteSpaceRegex: /^\S+(?:\s+\\S+)*$/,
  passportFieldRegex: /^[a-zA-Z0-9]*$/,
  production: false,
  authServiceUrl: 'https://hub-keycloak.uat.irembolabs.com',
  authRealm: 'irembo',
  authClientId: 'agents-portal',
  authSuccessRedirectUrl: 'https://agent-portal.uat.irembolabs.com',
  apiGatewayBaseUrl: 'https://agent-gateway.uat.irembolabs.com',
  IREMBOPAY_CDN:
    'https://dashboard.sandbox.irembopay.com/assets/payment/inline.js',
  IREMBOPAY_TEST: true,
  locale: 'IremboPay.locale.EN',
  anonymousCitizenId: 'd40d9c16-2f5b-4ae1-ba11-345209017b61',
  applicationByOther: true,
  iremboPrivacyPolicyUrl: 'https://irembo.gov.rw/support/legal-information',
  allowUploadPreviousFiles: false,
  DEFAULT_LOCALE: 'en-US',
  DEFAULT_LOCALE_NAME: 'English',
  NIDA_GET_ID_INFO_ENDPOINT_CODE: 'NIDAGETIDINFO',
  translationSourceUrl: 'https://agent-gateway.uat.irembolabs.com',
};
