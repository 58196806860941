/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import {
  INavbarItem,
  INavbarActionItem,
  ToastService,
  EUserType,
  IremboPasswordRegExp,
  IHttpSingleDataResponse,
  ExtractHttpErrorResponseCodeAndMessage,
} from '@irembo-andela/irembogov3-common';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { IRole } from './core/models/role.model';
import { IdleTimerService } from './core/services/idle-timer/idle-timer.service';
import { RoleService } from './core/services/role/role.service';
import { AuthService } from './core/services/auth/auth.service';
import { AgentsService } from './core/services/agents/agents.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { IChangePasswordRequest } from './core/models/changePassword/change-password-request';
import { IChangePasswordResponse } from './core/models/changePassword/change-password-response';
import { EUserRole } from './core/enums/allowed-roles-enums';
import { IFileDownloadResponse } from './core/models/applications/get-application-response.model';
import { IIremboFile } from './core/models/applications/application-model';
import { ProfileService } from './core/services/profile-picture/profile.service';
import { BehaviorSubject, Subscription, filter, finalize } from 'rxjs';
import { environment } from '../environments/environment';
import { IGetAgentResponse } from './core/models/agent/get-agent-response.model';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { ILocale } from './core/models/locales.model';
import { LocaleService } from './core/services/locale/locale.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { IGetProfileResponse } from './core/models/agentProfile/get-profile-response.model';
import { IIremboRole } from './core/models/agentProfile/profile-model';
import {
  EAgencyType,
  IIremboAgency,
} from './core/models/agency/get-agency-response.model';
import { IIremboAgentPermissions } from './core/models/agents/agent-model';
enum PaneContentType {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  UPDATE_PROFILE_PICTURE = 'UPDATE_PROFILE_PICTURE',
}

@Component({
  selector: 'irembogov-root',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit, OnDestroy {
  userProfile?: KeycloakProfile;
  isLoggedIn?: boolean;
  sidePaneOpen = false;

  modalOpen = false;

  private subscriptions = new Subscription();

  @ViewChild('logoutWarningContent') logoutWarningModal!: ElementRef;

  acceptedRoles: IRole[] = [
    { code: 'ROLE_AGENCY_ADMIN', name: 'Agency Admin' },
    { code: 'ROLE_AGENT', name: 'Agent' },
  ];

  userRoles?: string[] = [];
  currentRole?: IRole;

  navbarItems: INavbarItem[] = [];
  navbarActions: INavbarActionItem[] = [];
  profileSectionNavActionItems: INavbarActionItem[] = [];
  isChangePasswordLoading = false;
  passwordRegExp: RegExp = IremboPasswordRegExp;
  customCss = 'dropDownCustomCss';
  isLoadingProfilePicture = false;
  profilePicture!: IIremboFile;
  paneContentType = new BehaviorSubject<PaneContentType | undefined>(undefined);
  currentPaneContentTypeData = {
    title: '',
    description: '',
    roundedWrapContent: false,
  };
  paneContentTypeData = {
    CHANGE_PASSWORD: {
      title: 'Change your password',
      description: '',
      roundedWrapContent: true,
    },
    UPDATE_PROFILE_PICTURE: {
      title: 'Update Profile',
      description: 'Edit your profile info',
      roundedWrapContent: true,
    },
    NO_TYPE: {
      title: '',
      description: '',
      roundedWrapContent: false,
    },
  };
  // userProfile: IIremboAgentProfile = {} as IIremboAgentProfile;
  updateProfileInfo!: FormGroup;
  imgFile!: string;
  isUpdateLoading = false;
  userProfileId!: string;
  keycloakUserProfile = {};
  hasProfilePic = false;
  agentNationalId: string | undefined = undefined;
  @ViewChild('inputFile', { static: false }) myInputFile!: ElementRef;
  @ViewChild('logoutModalTemplate') logoutModalTemplate!: TemplateRef<any>;
  EUserRole = EUserRole;
  activeLocale: string;
  activeLocaleName: string;
  supportedLocales: ILocale[] = [];
  agencyName = '';
  agencyId = '';
  currentRoute: string | undefined;

  constructor(
    private itos: IdleTimerService,
    private ks: KeycloakService,
    private roleService: RoleService,
    private router: Router,
    private authService: AuthService,
    private modalService: NgbModal,
    private agentService: AgentsService,
    private toastService: ToastService,
    private profilePictureService: ProfileService,
    private fb: FormBuilder,
    private keycloakService: KeycloakService,
    private localeService: LocaleService,
    public translate: TranslateService
  ) {
    this.activeLocale =
      localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE;
    this.activeLocaleName =
      localStorage.getItem('localeName') ?? environment.DEFAULT_LOCALE_NAME;
    translate.addLangs([this.activeLocale]);
    translate.setDefaultLang(this.activeLocale);

    this.updateProfileInfo = this.fb.group({
      profilePicture: [null],
    });

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.currentRoute = this.router.url;
      });

    this.subscriptions.add(
      this.agentService.agentDetails$.subscribe(res => {
        if (res && res['nationalId']) {
          this.agentNationalId = res['nationalId'] as string;
        }
      })
    );
  }

  changePasswordForm: FormGroup = new FormGroup({
    currentPassword: new FormControl<string>('', [Validators.required]),
    newPassword: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(this.passwordRegExp),
    ]),
    confirmPassword: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(this.passwordRegExp),
    ]),
  });

  async ngOnInit() {
    this.isLoggedIn = await this.keycloakService.isLoggedIn();
    if (this.isLoggedIn)
      this.userProfileId = (await this.keycloakService.loadUserProfile())
        .id as string;
    this.profilePictureService.profilePictureSubject.subscribe(
      profilePicture => {
        if (profilePicture) {
          this.profilePicture = profilePicture;
        }
      }
    );

    this.roleService.currentRole.subscribe(role => {
      if (role !== undefined) {
        this.currentRole = role;
        this.getRoleNavbarItems();
        if (this.currentRole.code === EUserRole.ROLE_AGENCY_ADMIN) {
          this.getAgencyAdminUser();
          this.router.navigate(['/dashboard/agents/agents-list']);
        } else {
          this.getAgentByUserId(this.userProfileId);
          this.router.navigate(['/dashboard/stats/home']);
        }
      }
    });
    this.handleAuthentication();
    this.authService.showLogOutWarningModal$.subscribe((show: boolean) => {
      if (show) {
        this.openModal(this.logoutWarningModal);
      }
    });
    this.paneContentType.subscribe(value => {
      this.currentPaneContentTypeData =
        this.paneContentTypeData[value ?? 'NO_TYPE'];
    });
    this.getProfilePicture();
    this.getSupportedLocales();
  }

  get currentPassword() {
    return this.changePasswordForm.get('currentPassword');
  }

  get newPassword() {
    return this.changePasswordForm.get('newPassword');
  }

  get confirmPassword() {
    return this.changePasswordForm.get('confirmPassword');
  }

  async handleAuthentication() {
    if (await this.ks.isLoggedIn()) {
      // start idle-time-out-service
      this.itos.start();

      this.userProfile = await this.ks.loadUserProfile();
      this.getProfilePicture();

      this.acceptedRoles.forEach(role => {
        if (this.ks.getUserRoles().includes(role.code)) {
          this.userRoles?.push(role.name);

          if (this.roleService.currentRole.value == undefined) {
            this.roleService.currentRole.next(role);
          }
        }
      });
    }
  }

  roleChange($event: string) {
    if ($event === 'Agency Admin') {
      this.roleService.currentRole.next(this.acceptedRoles[0]);
    } else if ($event === 'Agent') {
      this.roleService.currentRole.next(this.acceptedRoles[1]);
    }
  }

  logout() {
    this.authService.logOutUser();
  }

  getRoleNavbarItems() {
    let navbarItems: INavbarItem[] = [];
    let navbarActions: INavbarActionItem[] = [];
    let profileSectionNavActionItems: INavbarActionItem[] = [];

    if (this.currentRole?.code === 'ROLE_AGENCY_ADMIN') {
      navbarItems = [
        {
          name: 'Dashboard',
          routerLink: '/dashboard/stats',
          icon: 'fa-solid fa-grip',
        },
        {
          name: 'Agents',
          routerLink: '/dashboard/agents/agents-list',
          icon: 'fa-solid fa-users',
        },
      ];

      navbarActions = [];
      profileSectionNavActionItems = [
        {
          name: 'Update profile picture',
          icon: 'fa-regular fa-user',
          action: 'update-profile-picture',
        },
      ];
    }

    if (this.currentRole?.code === 'ROLE_AGENT') {
      navbarItems = [
        {
          name: 'Services',
          routerLink: '/dashboard/stats/home',
          icon: 'fa-solid fa-house',
        },
        {
          name: 'Find Applications',
          routerLink: '/dashboard/applications/find-application',
          icon: 'fa-regular fa-folder',
        },
        {
          name: 'Support',
          routerLink: '/dashboard/support',
          icon: 'fa-solid fa-file',
        },
      ];

      navbarActions = [
        {
          name: 'Support center',
          icon: 'fa-solid fa-magnifying-glass',
          action: 'find-applications',
        },
      ];

      profileSectionNavActionItems = [];
    }

    this.navbarItems = navbarItems;
    this.navbarActions = navbarActions;
    this.profileSectionNavActionItems = profileSectionNavActionItems;
  }

  openLogoutModal() {
    this.modalService
      .open(this.logoutModalTemplate, { centered: true })
      .result.then(
        response => {
          if (response === 'logout') {
            this.authService.logOutUser();
            return;
          }
          this.itos.start();
        },
        () => {
          this.itos.start();
        }
      );
  }

  openModal(content: unknown): void {
    if (this.modalOpen) {
      return;
    }
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        result => {
          this.modalOpen = false;

          if (result === 'logout') {
            this.authService.updateIncompleteProcessState(false);
            this.logout();
            return;
          }
          this.itos.start();
        },
        () => {
          this.modalOpen = false;
          this.itos.start();
        }
      );
  }

  openSidePaneForChangePassword(): void {
    this.sidePaneOpen = !this.sidePaneOpen;
    this.paneContentType.next(PaneContentType.CHANGE_PASSWORD);
  }

  async onFormSubmit() {
    if (this.checkIfChangePasswordFormIsValid(this.changePasswordForm)) {
      if (this.newPassword?.value === this.confirmPassword?.value) {
        this.userProfile = await this.ks.loadUserProfile();
        this.isChangePasswordLoading = true;
        const changePasswordRequest: IChangePasswordRequest = {
          username: this.userProfile.username as string,
          newPassword: this.newPassword?.value,
          userType: 'OTHER',
          usernameType: 'EMAIL_ADDRESS',
        };
        this.agentService.changePassword(changePasswordRequest).subscribe({
          next: (res: IChangePasswordResponse) => {
            this.isChangePasswordLoading = false;
            this.toastService.show({
              body: res.message,
              type: 'success',
              delay: 5000,
            });
            this.cancelForm();
          },
          error: error => {
            this.isChangePasswordLoading = false;
            this.toastService.show({
              body: error.error.message,
              type: 'error',
              delay: 5000,
            });
          },
        });
      } else {
        this.toastService.show({
          body: 'Passwords do not match',
          type: 'error',
          delay: 5000,
        });
      }
    }
  }

  handleNavAction($event: string) {
    switch ($event) {
      case 'change-password':
        this.openSidePaneForChangePassword();
        break;
      case 'update-profile-picture':
        this.router.navigate(['dashboard', 'users', 'update-profile-picture']);
        break;
      default:
        break;
    }
  }

  cancelForm() {
    this.changePasswordForm.reset();
    this.openSidePaneForChangePassword();
  }

  private checkIfChangePasswordFormIsValid(form: FormGroup): boolean {
    if (form.invalid) {
      this.toastService.show({
        body: 'All fields are required',
        type: 'error',
        delay: 5000,
      });
      return false;
    }
    return true;
  }

  handleNavbarActionClick($event: string) {
    switch ($event) {
      case 'find-applications':
        this.router.navigate(['/dashboard/applications/find-application']);
        break;
    }
  }

  handleProfileClick() {
    if (this.currentRole?.code === EUserRole.ROLE_AGENCY_ADMIN) {
      this.router.navigate(['/dashboard/users/user-profile']);
    }
    if (this.currentRole?.code === EUserRole.ROLE_AGENT) {
      this.router.navigate(['/dashboard/users/agent/profile']);
    }
  }

  getProfilePicture() {
    this.isLoadingProfilePicture = true;
    this.agentService
      .getProfilePicture(this.userProfile?.id as string, EUserType.OTHER)
      .subscribe({
        next: (response: IFileDownloadResponse) => {
          this.isLoadingProfilePicture = false;
          if (response.data.file) this.hasProfilePic = true;
          this.profilePicture = response.data;
        },
        error: () => {
          this.isLoadingProfilePicture = false;
        },
      });
  }

  openSidePaneForUpdateProfile(): void {
    this.sidePaneOpen = !this.sidePaneOpen;
    this.paneContentType.next(PaneContentType.UPDATE_PROFILE_PICTURE);
  }

  paneToggle($event: boolean) {
    this.sidePaneOpen = $event;
    if (!$event) {
      this.paneContentType.next(undefined);
    }
  }

  public get paneContentTypeEnum(): typeof PaneContentType {
    return PaneContentType;
  }

  prFileChange(event: Event) {
    const target = event.target;
    if (target instanceof HTMLInputElement) {
      if (target.files && target.files[0].size >= 5242880) {
        this.toastService.show({
          body: 'Image file exceeds 5mb',
          type: 'error',
          delay: 5 * 1000,
        });
        return;
      } else if (
        target.files &&
        !target.files[0].name.match(/\.(jpg)$/) &&
        target.files &&
        !target.files[0].name.match(/\.(jpeg)$/) &&
        target.files &&
        !target.files[0].name.match(/\.(png)$/)
      ) {
        this.toastService.show({
          body: 'Profile picture must be JPEG, JPG, or PNG',
          type: 'error',
          delay: 5 * 1000,
        });
        return;
      }
      if (target.files && target.files.length > 0) {
        const file = target.files[0];
        this.updateProfileInfo.patchValue({
          profilePicture: file,
        });
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.imgFile = e.target.result;
        };
        reader.readAsDataURL(target.files[0]);
      }
    }
  }

  resetInputFile() {
    this.myInputFile.nativeElement.value = '';
    this.imgFile = '';
    this.updateProfileInfo.reset({
      profilePicture: [null],
    });
  }

  updateUserProfile() {
    this.isUpdateLoading = true;
    const formData = new FormData();

    formData.append(
      'profilePicture',
      this.updateProfileInfo.get('profilePicture')?.value
    );
    formData.append('id', this.userProfileId);
    formData.append('clientId', environment.authClientId);
    formData.append('userType', EUserType.OTHER);
    this.agentService
      .updateUserProfile(formData)
      .pipe(finalize(() => (this.isUpdateLoading = false)))
      .subscribe({
        next: response => {
          if (response.status === true) {
            const message = `Profile picture updated`;
            this.toastService.show({ body: message, type: 'success' });
            this.profilePictureService.profilePictureSubject.next(
              response.data
            );
          } else {
            const message = `Error updating profile`;
            this.toastService.show({ body: message, type: 'error' });
          }
          this.onClosePane();
          this.getProfilePicture();
        },
        error: err => {
          this.toastService.show({ body: err.error.message, type: 'error' });
        },
      });
  }

  onClosePane() {
    this.sidePaneOpen = false;
    this.myInputFile.nativeElement.value = '';
    this.imgFile = '';
    this.updateProfileInfo.reset({
      profilePicture: [null],
    });
  }

  getAgentByUserId(userId: string) {
    this.agentService.getAgentByUserId(userId).subscribe({
      next: (res: IGetAgentResponse) => {
        this.agentNationalId = res.data.nationalId;
        this.agencyId = res.data.agencyId;
        this.getAgencyInfoById(EUserRole.ROLE_AGENT);
      },
    });
  }

  getLocaleIcon(locale: string): string {
    return getUnicodeFlagIcon(
      locale.includes('-') ? locale.split('-')[1] : locale
    );
  }

  getSupportedLocales() {
    this.localeService.getSupportedLocales().subscribe({
      next: response => {
        this.supportedLocales = response.data.content;
        this.localeService.supportedLocales.next(this.supportedLocales);
        this.translate.addLangs(this.supportedLocales.map(lang => lang.locale));
      },
      error: error => {
        this.toastService.show({
          body: error.error.responseMessage ?? error.error.message,
          type: 'error',
          delay: 5000,
        });
      },
    });
  }

  changeLocale(item: ILocale) {
    this.activeLocale = item.locale;
    this.activeLocaleName = item.name;
    localStorage.setItem('locale', item.locale);
    localStorage.setItem('localeName', item.name);
    this.translate.use(item.locale);
  }

  getAgencyAdminUser() {
    this.agentService.getUserById(this.userProfileId).subscribe({
      next: (res: IGetProfileResponse) => {
        const agencyAdminRoleInfo: IIremboRole | undefined =
          res.data.userRoles.find(
            (role: IIremboRole) => role.name === EUserRole.ROLE_AGENCY_ADMIN
          );
        if (!agencyAdminRoleInfo) {
          throw new Error('User is not an Agency Admin');
        }
        this.agencyId = agencyAdminRoleInfo.scopeId;
        this.getAgencyInfoById(EUserRole.ROLE_AGENCY_ADMIN);
      },
      error: (error: HttpErrorResponse) => {
        console.log(error, 'getAgencyAdminUser');
        const errorCodeAndMessage = ExtractHttpErrorResponseCodeAndMessage(
          error,
          'Your agency admin information could not be retrieved.'
        );
        this.toastService.show({
          body: errorCodeAndMessage.message,
          type: 'success',
          delay: 5000,
        });
        this.logout();
      },
    });
  }

  getAgencyInfoById(
    roleType: EUserRole.ROLE_AGENCY_ADMIN | EUserRole.ROLE_AGENT
  ) {
    if (!this.agencyId) {
      this.toastService.show({
        title: 'No Agency Assigned.',
        body: 'You are not asssigned to any agency. You will be logged out.',
        type: 'error',
        delay: 3000,
      });
      console.log('NO AGENCY ASSIGNED.');
      setTimeout(() => {
        this.logout();
      }, 3000);
      return;
    }
    this.agentService.getAgencyInfoById(this.agencyId).subscribe({
      next: (res: IHttpSingleDataResponse<IIremboAgency>) => {
        this.agencyName = res.data.name;
        this.agencyId = res.data.id;
        this.agentService.setUserAgency(res.data);
        if (
          res.data.type === EAgencyType.INTERNAL &&
          roleType === EUserRole.ROLE_AGENT
        ) {
          this.fetchAgentAssignedPermissions();
        }
      },
      error: (error: HttpErrorResponse) => {
        const errorCodeAndMessage = ExtractHttpErrorResponseCodeAndMessage(
          error,
          'Agency details for Agency Admin could not be retrieved.'
        );
        this.toastService.show({
          body: errorCodeAndMessage.message,
          type: 'success',
          delay: 5000,
        });
        this.logout();
      },
    });
  }

  fetchAgentAssignedPermissions() {
    this.subscriptions.add(
      this.agentService.getAgentAssignedAccessPermissions().subscribe({
        next: (res: IHttpSingleDataResponse<IIremboAgentPermissions>) => {
          if (res.data) {
            this.agentService.setPermissions(res.data.permissions);
          }
        },
        error: (error: HttpErrorResponse) => {
          const errorCodeAndMessage = ExtractHttpErrorResponseCodeAndMessage(
            error,
            'Agent permissions could not be retrieved.'
          );
          this.toastService.show({
            body: errorCodeAndMessage.message,
            type: 'error',
            delay: 5000,
          });
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
