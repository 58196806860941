import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IIremboFile } from '../../models/applications/application-model';
@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  profilePictureSubject: Subject<IIremboFile | undefined | null> = new Subject<
    IIremboFile | undefined | null
  >();
}
