import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFeatureService } from '../../models/featured-service.model';
import { PublicServicesService } from '../../services/public-services/public-services.service';
import { ToastService } from '../../services/toast/toast.service';

@Component({
  selector: 'irembogov-featured-service-card',
  templateUrl: './irembogov-featured-service-card.component.html',
  styleUrls: ['./irembogov-featured-service-card.component.scss'],
})
export class IrembogovFeaturedServiceCardComponent implements OnInit {
  constructor(
    private serviceService: PublicServicesService,
    private toastService: ToastService
  ) {}

  @Input() featuredCustom = 'featuredCustom';
  @Input() serviceBaseUrl!: string;

  @Output() cardClick = new EventEmitter<IFeatureService>();

  featuredServices: IFeatureService[] = [];

  ngOnInit(): void {
    this.getFeaturedServices();
  }

  getFeaturedServices() {
    this.serviceService.getFeaturedServices(this.serviceBaseUrl).subscribe({
      next: response => {
        this.featuredServices = response.data.content;
      },
      error: err => {
        this.toastService.show({
          body: err.error.message,
          type: 'error',
          delay: 5000,
        });
      },
    });
  }
}
