import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalFooterComponent } from './portal-footer/portal-footer.component';
import { SharedModule } from '../modules/shared/shared.module';

@NgModule({
  declarations: [PortalFooterComponent],
  imports: [CommonModule, SharedModule],
  exports: [PortalFooterComponent],
})
export class LayoutModule {}
