import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'irembogov-browse-button',
  templateUrl: './irembogov-browse-button.component.html',
  styleUrls: ['./irembogov-browse-button.component.scss'],
})
export class IrembogovBrowseButtonComponent {
  @Input() label: string | undefined;
  @Input() icon: string | undefined;
  @Input() enabled: boolean | undefined = true;

  @Output() buttonClick = new EventEmitter<undefined>();
}
